<template>
	<div>
	<div
		v-for="block in blocks"
		:key="block.id"
	>
		<div 
			class="hidden-sm-and-down"
		>
		
		<!-- This handles the "default" block type, which is picture on one side, text on the other.	Multiple pictures turned into a Carousel. -->
		<div v-if="block.layout=='default' || block.layout.length==0">

			<!-- If we have pictures -->
			<div 
				v-if="block.links&&block.links.length>0"
				class=rowCont
				:style="block.links[0].color==''?'':'background-color: ' + block.links[0].color1"
			>
				<div
					:class="blockLayout[block.label].imgHolderClass"
				>
					<v-carousel v-model="model"
						:height='block.label=="img_center_block_big"?800:600'
						:cycle='block.autoplay=="true"?true:false'
						:interval=block.delay
						:hide-delimiters='block.autoplay=="true"?true:false'
						hide-delimiter-background=true
						:show-arrows="block.links.length>1?true:false"
						show-arrows-on-hover=true
					>
						<v-carousel-item
							class=carousel
							v-for="link in block.links"
							:key="link.id"
							v-html="link.link"
							transition="v-fade-transition"
							:href="link.target"
						>
							{{ link.link }}
						</v-carousel-item>
					</v-carousel>
				</div>
				<div
					:class=blockLayout[block.label].txtHolderClass
				>
					<div>
						<div
							:style="[titleFontStyle]"
						> 
							<span 
								v-html="block.title"
							>
							</span>
						</div>
						<div
							:style="[textFontStyle]"
						> 
							<span
								v-html="block.text"
							></span>
						</div>
					</div>
					<div
						style="float: left; padding: 6px 6px 6px 0px;"
						v-for="button in block.buttons"
						:key="button.id"
					>
						<v-btn
							depressed
							:href=button.target
						>
							{{ button.title }}
							<v-icon>
								{{ button.icon }}
							</v-icon>
						</v-btn>
					</div>
				</div>	
			</div>

			<!-- If we don't have pictures -->
			<div v-else
				class=rowCont
				:style="block.bg_color.length>0?'background-color:'+block.bg_color:'background-image: url('+block.background+');'"
			>
				<div class=center>
					<div>
						<div
							:style="[titleFontStyle]"
						>
							<span 
								v-html="block.title"
							>
							</span>
						</div>
						<div class=multi-cont-text>
							<div
								:style="[textFontStyle]"
							> 
							<span
								v-html="block.text"
							></span>
							</div>
						</div>
					</div>
				</div>
				<div class=center>
					<div class="multi-cont">
					<div
						style="float: left; padding: 6px 6px 6px 0px;"
						v-for="button in block.buttons"
						:key="button.id"
					>
						<v-btn
							depressed
							:href=button.target
						>
							{{ button.title }}
							<v-icon>
								{{ button.icon }}
							</v-icon>
						</v-btn>
					</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Side by side block -->
		<div 
			v-else-if="block.layout=='side_by_side' || block.layout=='blog'"
			class="rowCont"
			:style="block.bg_color.length>0?'background-color:'+block.bg_color:'background-image: url('+block.background+');'"
		>
			<div class=center>
				<div
					:style="[titleFontStyle]"
				>
							<span 
								v-html="block.title"
							>
							</span>
				</div>
			</div>
			<div class=center>
				<div 
					class="multi-cont"
				>
					<div
						v-if="block.layout=='blog'"
					>
						<v-container fluid>
						<v-row dense>
						<v-col
							v-for="blog in blogs"
							:key="blog.id"
							:cols="12/blogs.length"
						>
						<v-card
							class="mx-auto"
							max-width="600px"
							height="520px"
						>	
							<v-img
								class="mx-auto primary_img rounded"
								alt="Blog Header"
								:src="blog.image"
								transition="scale-transition"
								height="320px"
							/>
							<v-card-title>
								{{ blog.title }}	
							</v-card-title>
							<v-card-subtitle>
								By 
								<a 
									:href="'/blogger/'+blog.creator_id+'/'" 
								>
									{{ blog.first_name }} {{ blog.last_name}} 
								</a>
								on {{ blog.readableTimestamp }}
							</v-card-subtitle>
							<v-card-text>
								{{ blog.blurb }}
							</v-card-text>
							<v-card-actions>
								<v-btn
									outlined
									v-on:click="go('/blog/'+blog.blog_id+'/')"
								>
									View
								</v-btn>
							</v-card-actions>
						</v-card>
						</v-col>
						</v-row>
						</v-container>
					</div>
					<div 
						v-else
						@mouseover="picLinkMO($event)"
						@mouseleave="picLinkML($event)"
						@click="go(link.target)"
						class=link-cont
						v-for="link in block.links"
						:key="link.id"
						v-html="link.link + '<div class=linkLayer></div><div class=textCont>'+link.title+'</div>'"
					>
					</div>
				</div>
			</div>
			<div class=center>
				<div
					:style="[textFontStyle]"
				> 
							<span
								v-html="block.text"
							></span>
				</div>
			</div>
			<div class=center>
				<div class="multi-cont">
					<div
						style="float: left; padding: 6px;"
						v-for="button in block.buttons"
						:key="button.id"
					>
						<v-btn
							depressed
							:href=button.target
						>
							{{ button.title }}
							<v-icon>
								{{ button.icon }}
							</v-icon>
						</v-btn>
					</div>
				</div>
			</div>
		</div>

		<!-- Other block types will be handled here -->
		<div
			v-else
		>
		</div>

		<div class=gutter></div>
		</div>

		<!-- Phone View -->
		<div 
			class="hidden-md-and-up"
		>
			<div 
				v-if="block.links&&block.links.length>0"
				class=mobileRowCont
				:style="'background-color: ' + block.links[0].color1"
			>
				<v-carousel v-model="model"
					:cycle='block.autoplay=="true"?true:false'
					:interval=interval
					:hide-delimiters='block.autoplay=="true"?true:false'
					hide-delimiter-background=true
					:show-arrows="block.links.length>1?true:false"
					show-arrows-on-hover=true
				>
					<v-carousel-item
						class=carousel
						v-for="link in block.links"
						:key="link.id"
						v-html="link.link"
						transition="v-fade-transition"
						:href="link.target"
					>
					</v-carousel-item>
				</v-carousel>
				<div>
					<div style="margin: 6px">
						<div class=center>
							<div
								:style="[titleFontStyleMobile]"
							>
							<span 
								v-html="block.title"
							>
							</span>
							</div>
						</div>
						<div
							:style="[textFontStyleMobile]"
						> 
							<span
								v-html="block.text"
							></span>
						</div>
					</div>
					<div class=center>	
						<div
							style="margin: 6px;"	
							v-for="button in block.buttons"
							:key="button.id"
						>
							<v-btn
								depressed
								:href=button.target
							>
								{{ button.title }}
								<v-icon>
									{{ button.icon }}
								</v-icon>
							</v-btn>
						</div>
					</div>
				</div>
			</div>
			<div v-else
				class=rowCont
				:style="block.bg_color.length>0?'background-color:'+block.bg_color:'background-image: url('+block.background+');'"
			>
				<div>
					<div style="margin: 6px">
						<div class=center>
							<div
								:style="[titleFontStyleMobile]"
							>
							<span 
								v-html="block.title"
							>
							</span>
							</div>
						</div>
						<div
							:style="[textFontStyleMobile]"
						> 
							<span
								v-html="block.text"
							></span>
						</div>
					</div>
					<div class=center>
						<div
							style="margin: 6px;"	
							v-for="button in block.buttons"
							:key="button.id"
						>
							<v-btn
								depressed
								:href=button.target
							>
								{{ button.title }}
								<v-icon>
									{{ button.icon }}
								</v-icon>
							</v-btn>
						</div>
					</div>
				</div>	
			</div>
			<div class=gutter></div>
		</div>
	</div>
	</div>
</template>

<script>
module.exports = {
	data() {
		return {
			textFontStyleMobile: {
			},
			titleFontStyleMobile: {
			},
			textFontStyle: {
			},
			titleFontStyle: {
			},
			blocks: {},
			blogs: [],
			max_blogs: 2,
			link: {
				blur: {
					"bottom": "8px",
					"left": "16px",
					"fontSize": "22px",
					"fontColor": "black"
				},
				focus: { 
					"bottom": "150px",
					"left": "40px",
					"fontSize": "40px",
					"fontColor": "white"
				}
			},
			blockLayout: {
				"big": {
				},
				"img_center_block_big": {
					imgHolderClass: "img_center",
					txtHolderClass: "hidden"
				},
				"img_center_block": {
					imgHolderClass: "img_center",
					txtHolderClass: "hidden"
				},
				"img_left_block": {
					imgHolderClass: "left",
					txtHolderClass: "right"
				},
				"img_right_block": {
					imgHolderClass: "right",
					txtHolderClass: "left"
				},
				"two_pic_block": {
					imgHolderClass: "left",
					txtHolderClass: "right"
				},
				"single_pic_block": {
					imgHolderClass: "right",
					txtHolderClass: "left"
				},
				"top_block": {
					imgHolderClass: "left",
					txtHolderClass: "right"
				},
				"side_by_side": {
					imgHolderClass: "left",
					txtHolderClass: "right"
				}
			}
		};
	},
	methods: {
		go(href) {
			console.log("routing to " + href)
			window.location.href=href
			//this.$router.push({name:href})
		},
		picLinkMO(e) {
			var t = event.target||event.srcElement
			var bg = t.parentElement.children[1]; 
			var text = t.parentElement.children[2]; 
			text.style.bottom=this.link.focus.bottom;
			text.style.left=this.link.focus.left;
			text.style.fontSize=this.link.focus.fontSize;
			text.style.color=this.link.focus.fontColor;
			bg.style.opacity = "50%";
			bg.style.bottom="0px";
			bg.style.left="0px";
			bg.style.backgroundColor="black";
			bg.style.width="100%";
			bg.style.height="100%";
		},
		picLinkML(e) {
			var t = event.target||event.srcElement
			var bg = t.children[1];
			var text = t.children[2]; 
			text.style.bottom=this.link.blur.bottom;
			text.style.left=this.link.blur.left;
			text.style.fontSize=this.link.blur.fontSize;
			text.style.color=this.link.blur.fontColor;
			bg.style.width="";
			bg.style.height="0";
		},
		getReadableDate(ts) {
			console.log(ts);
			var t = new Date(ts*1000);
			var options = { year: 'numeric', month: 'long', day: 'numeric' };
			var times = t.toLocaleDateString("en-US", options);
			console.log(times);
			return(times);
		},
	},
	mounted() { 
		this.$http.get("/svc/page"+this.$route.path).then(response => { 
			this.blocks = response.data.pageBlocks;
			this.titleFontStyle = response.data.global.title_font_style;
			this.titleFontStyleMobile = response.data.global.title_font_style_mobile;
			this.textFontStyle = response.data.global.text_font_style;
			this.textFontStyleMobile = response.data.global.text_font_style_mobile;

			// If we have a blog block, load the blogs
			var load_blogs = false;

			for(var b in this.blocks) {
				if(this.blocks[b].layout=="blog") {
					load_blogs = true;
					break;
				}
			}

			if(load_blogs) {
				this.$http.get("/svc/blogs/").then(response => { 
					var blogs = response.data.blog
					var i=0;
					for(var b in blogs) {
						console.log(JSON.stringify(blogs[b]))
						if(blogs[b].published) {
							i++
							blogs[b].readableTimestamp = this.getReadableDate(blogs[b].ts)
							this.blogs.push(blogs[b])
							console.log("pushing")
						}

						if(i>=this.max_blogs) {
							break;
						}
					}
					
					this.$forceUpdate(); 
				})
			}

		}) 
	}
};
</script>

<style scoped>
.hidden {
	display: none;
}

.img_center {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
	padding-right: 2%;
	padding-left: 2%;
}

.left {
	display: inline-block;
	float: left;
	width: 50%;
	padding-right: 2%;
	padding-left: 2%;
}

.right {
	display: inline-block;
	float: right;
	width: 50%;
	padding-right: 2%;
	padding-left: 2%;
}

.mobileRowCont {
	display: inline-block;
	width: 100%;
}

.rowCont {
	display: inline-block;
	width: 100%;
	background-repeat: repeat-x;
}

.gutter {
	display:inline-block;
	width: 100%;
	height: 5px;
}

.carousel >>> img {
	object-fit: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	max-height: 740px
}

.carousel >>> video {
	object-fit: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	max-height: 740px
}

.multi-cont {
	display: flex;
	align-items: center;
	justify-content: center;
}

.multi-cont-text {
	text-align: left;
	display: inline-block;
	width: 75%;
}

.multi-cont >>> img {
	display: block;
	max-height: 400px;
	height: 400px;
	width: auto;
	float: left;
	padding: 6px;
}

.multi-cont >>> video {
	display: block;
	max-height: 400px;
	height: 400px;
	width: auto;
	float: left;
	padding: 6px;
}

.link-cont {
	position: relative;
	text-align: center;
	color: black;
}

.link-cont >>> div.linkLayer  {
	position: absolute;
	transition-property: all;
	transition-duration: 0.1s;
	transition-timing-function: linear;
}

.link-cont >>> div.textCont  {
	font-weight: bold;
	font-size: 22px;
	bottom: 8px;
	left: 16px;
	position: absolute;
	transition-property: all;
	transition-duration: 0.25s;
	transition-timing-function: linear;
}

</style>
